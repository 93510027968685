import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Arrow from '../../images/ico-arrow-down.svg';

const serializers = {
  types: {
    block: ({ children }) => <h1 className="split-n-wrap">{children}</h1>,
  },
};

export default function HeroAbout3({ data }) {
  return (
    <div className="hero t1-about-hero">
      <div className="heading">
        <div className="wrapper col-grid">
          <div className="empty" />
          <div className="text-content">
            <PortableText blocks={data._rawTitle} serializers={serializers} />
          </div>
        </div>
      </div>

      <div className="hero-pic">
        <div className="wrapper col-grid">
          <div className="down-arrow">
            <img src={Arrow} alt="arrow" className="arrow" />
          </div>

          <div className="pic-contain item-img-wrap">
            <div className="pic item-img">
              <GatsbyImage
                image={data.pic.asset.gatsbyImageData}
                alt="Rekhchand Sahu"
              />
            </div>
          </div>

          <div className="pic-contain mobile item-img-wrap">
            <div className="pic item-img">
              <GatsbyImage
                image={data.picMobile.asset.gatsbyImageData}
                alt="Rekhchand Sahu"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
