import React from 'react';
import PortableText from '@sanity/block-content-to-react';

const serializers = {
  types: {
    block: ({ children, node }) => {
      if (node.style === 'h4') {
        return <h3 className="big split-lines">{children}</h3>;
      }
      return <p className="para split-lines">{children}</p>;
    },
  },
};

export default function WhoIAm1({ data }) {
  return (
    <section className="who-i-am type1">
      <div className="wrapper">
        <div className="who-content">
          <div className="floating-tag pink ball-reveal">
            <div className="tag-content">
              <p>Who I am</p>
            </div>
          </div>
          <div className="who-texts">
            <PortableText blocks={data} serializers={serializers} />
          </div>
        </div>
      </div>
    </section>
  );
}
