import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Squiggle3 from '../../icons/Squiggle3';
import Squiggle4 from '../../icons/Squiggle4';

const serializers = {
  types: {
    block: ({ children }) => <h1>{children}</h1>,
  },
  marks: {
    strong: ({ children }) => (
      <span className="squiggle">
        {children}
        <Squiggle3 />
        <Squiggle4 />
      </span>
    ),
  },
};

export default function HeroAbout2({ data }) {
  return (
    <div className="hero t2-about-hero hero-squiggle">
      <div className="heading">
        <div className="wrapper">
          <PortableText blocks={data._rawTitle} serializers={serializers} />
        </div>
      </div>
      <div className="hero-pic">
        <div className="pic-contain item-img-wrap">
          <div className="pic item-img">
            <GatsbyImage
              image={data.pic.asset.gatsbyImageData}
              alt="Rekhchand Sahu"
            />
          </div>
        </div>
        <div className="pic-contain mobile item-img-wrap">
          <div className="pic item-img">
            <GatsbyImage
              image={data.picMobile.asset.gatsbyImageData}
              alt="Rekhchand Sahu"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
