import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';

const serializers = {
  types: {
    block: ({ children }) => <h1 className="show-up">{children}</h1>,
  },
  marks: {
    strong: ({ children }) => <span>{children}</span>,
  },
};

export default function HeroAbout3({ data }) {
  return (
    <div className="hero t3-about-hero">
      <div className="hero-pic item-img-wrap">
        <div className="hero-pic-inner item-img">
          <GatsbyImage
            image={data.pic.asset.gatsbyImageData}
            alt="Rekhchand Sahu"
          />
        </div>
      </div>

      <div className="hero-pic mobile item-img-wrap">
        <div className="hero-pic-inner item-img">
          <GatsbyImage
            image={data.picMobile.asset.gatsbyImageData}
            alt="Rekhchand Sahu"
          />
        </div>
      </div>

      <div className="about3-content">
        <div className="wrapper specialised-wrap">
          <div className="specialised">
            <p className="para info-data">Specialised in</p>
            <p className="web info-data">Websites &amp; Webapps</p>
          </div>
        </div>
        <div className="heading">
          <div className="wrapper">
            <PortableText blocks={data._rawTitle} serializers={serializers} />
          </div>
        </div>
      </div>
    </div>
  );
}
