import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function AboutServices({ data, tools }) {
  return (
    <section className="about-services">
      <div className="tools-marquee">
        <div className="all-tools change-direction">
          {tools.map((tool, i) => (
            <div className="tool" key={i}>
              <span>{tool.tool}</span>
              <div className="logo">
                <GatsbyImage
                  image={tool.logo.asset.gatsbyImageData}
                  alt={tool.tool}
                />
              </div>
            </div>
          ))}
          {tools.map((tool, i) => (
            <div className="tool" key={i}>
              <span>{tool.tool}</span>
              <div className="logo">
                <GatsbyImage
                  image={tool.logo.asset.gatsbyImageData}
                  alt={tool.tool}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
