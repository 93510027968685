import React from 'react';
import QuoteIcon from '../../images/theme1/ico-quote.svg';

export default function DesignQuote({ data }) {
  return (
    <section className="design-quote">
      <div className="wrapper col-grid">
        <div className="icon-contain">
          <div className="quote-icon">
            <img src={QuoteIcon} alt="Quote icon" />
          </div>
        </div>

        <div className="quote-body">
          <div className="quote-text">
            <h3 className="split-lines">{data.title}</h3>
            {data.url && (
              <a
                href={data.url}
                className="link element-reveal"
                target="_blank"
                rel="noreferrer"
              >
                Video link
              </a>
            )}
          </div>

          <p className="designer para element-reveal">– {data.name}</p>

          <div className="all-links">
            <p className="link open-heroes element-reveal">My heroes & gurus</p>
          </div>
        </div>
      </div>
    </section>
  );
}
