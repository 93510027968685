import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LocomotiveScroll from 'locomotive-scroll';
import PortableText from '@sanity/block-content-to-react';
/* eslint-disable */
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper';
/* eslint-enable */
import Close from '../../images/theme1/ico-close.svg';
import Close2 from '../../images/theme2/ico-close.svg';
import Close3 from '../../images/theme3/ico-close.svg';
import Swipe from '../../images/ico-swipe.svg';
import Scroll from '../../images/ico-mouse.svg';

const serializers = {
  types: {
    block: ({ children }) => <p className="para">{children}</p>,
  },
  marks: {
    link: ({ children, mark }) => (
      <a href={mark.href} target="_blank" rel="noreferrer" className="link">
        {children}
      </a>
    ),
  },
};

export default function FavsModal({ theme }) {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        favoriteThings {
          title
          allFavorites {
            category
            _rawItem
          }
        }
      }
    }
  `);
  const favsData = data.sanitySiteSettings.favoriteThings;

  useEffect(() => {
    if (theme) {
      const favsModal = document.querySelector('.favs-modal');
      const modalContent = favsModal.querySelector('.modal-content');
      const favsScroll = new LocomotiveScroll({
        el: modalContent,
        smooth: true,
        lerp: 0.09,
        scrollbarContainer: favsModal,
      });
      // Destroy when unmounts
      return () => {
        if (favsScroll) favsScroll.destroy();
      };
    }
  });

  return (
    <div className={`modal favs-modal bouncy ${theme}`}>
      {theme === 'theme1' && (
        <>
          <div className="modal-bg" />

          <div data-scroll-container className="modal-content">
            <div className="wrapper col-grid">
              <h2>{favsData.title}</h2>
              <div className="main-content favs-content">
                {favsData.allFavorites.map((item, i) => (
                  <div className="list-item fav-item" key={i}>
                    <div className="category">
                      <p>{item.category}</p>
                    </div>
                    <div className="info">
                      <PortableText
                        blocks={item._rawItem}
                        serializers={serializers}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="close-btn">
            <p>
              <img src={Close} alt="close" /> Close
            </p>
          </div>
        </>
      )}

      {theme === 'theme2' && (
        <>
          <div className="modal-bg" />

          <div data-scroll-container className="modal-content">
            <div className="heading">
              <h2>{favsData.title}</h2>

              <p className="swipe-scroll subtitle">
                <span className="scroll subtitle">
                  Scroll down
                  <img src={Scroll} alt="scroll" /> or swipe
                  <img src={Swipe} alt="swipe" />
                </span>
                <span className="swipe subtitle">
                  Swipe
                  <img src={Swipe} alt="swipe" />
                </span>
              </p>
            </div>

            <div className="main-content favs-content">
              <Swiper
                className="favs-swiper"
                slidesPerView="auto"
                slideToClickedSlide
                modules={[FreeMode, Mousewheel]}
                freeMode={{
                  sticky: false,
                  momentumBounce: false,
                }}
                mousewheel={{ sensitivity: 4.8 }}
              >
                {favsData.allFavorites.map((item, i) => (
                  <SwiperSlide className="list-item fav-item" key={i}>
                    <div className="item-contain">
                      <div className="item-content">
                        <div className="category">
                          <p className="para small">{item.category}</p>
                        </div>
                        <div className="info">
                          <PortableText
                            blocks={item._rawItem}
                            serializers={serializers}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="close-btn">
            <img src={Close2} alt="close" />
          </div>
        </>
      )}

      {theme === 'theme3' && (
        <>
          <div className="modal-bg type-1" />
          <div className="modal-bg type-2" />

          <div data-scroll-container className="modal-content">
            <div className="main-content favs-content">
              <h2>{favsData.title}</h2>
              {favsData.allFavorites.map((item, i) => (
                <div className="list-item fav-item" key={i}>
                  <div className="category">
                    <p>{item.category}</p>
                  </div>
                  <div className="info">
                    <PortableText
                      blocks={item._rawItem}
                      serializers={serializers}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="close-btn">
            <img src={Close3} alt="close" />
          </div>
        </>
      )}
    </div>
  );
}
