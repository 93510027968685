import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LocomotiveScroll from 'locomotive-scroll';
/* eslint-disable */
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper';
/* eslint-enable */

import Close from '../../images/theme1/ico-close.svg';
import Close2 from '../../images/theme2/ico-close.svg';
import Close3 from '../../images/theme3/ico-close.svg';
import Swipe from '../../images/ico-swipe.svg';
import Scroll from '../../images/ico-mouse.svg';

export default function HeroesModal({ theme }) {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        heroes {
          title
          text
          allHeroes {
            name
            company
            field
            url
            textColor
            bgColor
            pic {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `);
  const heroesData = data.sanitySiteSettings.heroes;

  useEffect(() => {
    if (theme) {
      const heroesModal = document.querySelector('.heroes-modal');
      const modalContent = heroesModal.querySelector('.modal-content');
      const heroesScroll = new LocomotiveScroll({
        el: modalContent,
        smooth: true,
        lerp: 0.09,
        scrollbarContainer: heroesModal,
      });
      // Destroy when unmounts
      return () => {
        if (heroesScroll) heroesScroll.destroy();
      };
    }
  });

  return (
    <div className={`modal heroes-modal bouncy ${theme}`}>
      {theme === 'theme1' && (
        <>
          <div className="modal-bg" />

          <div data-scroll-container className="modal-content">
            <div className="wrapper col-grid">
              <h2>{heroesData.title}</h2>

              <div className="main-content heroes-content">
                <p className="subtitle">{heroesData.text}</p>

                {heroesData.allHeroes.map((hero, i) => (
                  <a
                    href={hero.url}
                    target="_blank"
                    className="list-item hero-details"
                    key={i}
                    rel="noreferrer"
                  >
                    <div className="name">
                      <p className="para">{hero.name}</p>
                    </div>
                    <div className="company">
                      {hero.company && (
                        <p className="para small">{hero.company}</p>
                      )}
                    </div>
                    <div className="field">
                      <p className="para small">{hero.field}</p>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="close-btn">
            <p>
              <img src={Close} alt="close" /> Close
            </p>
          </div>
        </>
      )}

      {theme === 'theme2' && (
        <>
          <div className="modal-bg" />

          <div data-scroll-container className="modal-content">
            <div className="heading">
              <h2>{heroesData.title}</h2>

              <p className="swipe-scroll subtitle">
                <span className="scroll subtitle">
                  Scroll down
                  <img src={Scroll} alt="scroll" /> or swipe
                  <img src={Swipe} alt="swipe" />
                </span>
                <span className="swipe subtitle">
                  Swipe
                  <img src={Swipe} alt="swipe" />
                </span>
              </p>
            </div>

            <div className="main-content heroes-content">
              <Swiper
                className="hero-swiper"
                slidesPerView="auto"
                slideToClickedSlide
                modules={[FreeMode, Mousewheel]}
                freeMode={{
                  sticky: false,
                  momentumBounce: false,
                }}
                mousewheel={{ sensitivity: 4.8 }}
              >
                {heroesData.allHeroes.map((hero, i) => (
                  <SwiperSlide className="list-item hero-details" key={i}>
                    <a
                      href={hero.url}
                      target="_blank"
                      className="item-contain"
                      rel="noreferrer"
                      style={{ backgroundColor: hero.bgColor }}
                    >
                      <svg width="240" height="240" viewBox="0 0 240 240">
                        <defs>
                          <path
                            id="curve"
                            d="M40 120C40 75.8172 75.8172 40 120 40C164.183 40 200 75.8172 200 120"
                          />
                          <path
                            id="curve2"
                            d="M30 120C30 169.706 70.2944 210 120 210C169.706 210 210 169.706 210 120"
                          />
                        </defs>
                        <text fill={hero.textColor} textAnchor="middle">
                          <textPath
                            className="main-text"
                            xlinkHref="#curve"
                            startOffset="50%"
                          >
                            {hero.name}
                          </textPath>
                          <textPath
                            className="support-text"
                            xlinkHref="#curve2"
                            startOffset="50%"
                          >
                            {hero.company && `${hero.company}  •  `}
                            {hero.field}
                          </textPath>
                        </text>

                        <clipPath id="clip">
                          <circle id="pic-contain" cx="120" cy="120" r="50" />
                        </clipPath>
                        <image
                          href={hero.pic.asset.url}
                          className="pic"
                          width="100"
                          height="100"
                          x="70"
                          y="70"
                          clipPath="url(#clip)"
                        />
                      </svg>
                      {/* </div> */}
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className="close-btn">
            <img src={Close2} alt="close" />
          </div>
        </>
      )}

      {theme === 'theme3' && (
        <>
          <div className="modal-bg type-1" />
          <div className="modal-bg type-2" />

          <div data-scroll-container className="modal-content">
            <div className="main-content heroes-content">
              <h2>{heroesData.title}</h2>
              <p className="subtitle">{heroesData.text}</p>

              {heroesData.allHeroes.map((hero, i) => (
                <a
                  href={hero.url}
                  target="_blank"
                  className="list-item hero-details"
                  key={i}
                  rel="noreferrer"
                >
                  <div className="name">
                    <p className="para">{hero.name}</p>
                  </div>
                  <div className="company">
                    {hero.company && (
                      <p className="para small">{hero.company}</p>
                    )}
                  </div>
                  <div className="field">
                    <p className="para small">{hero.field}</p>
                  </div>
                </a>
              ))}
            </div>
          </div>

          <div className="close-btn">
            <img src={Close3} alt="close" />
          </div>
        </>
      )}
    </div>
  );
}
