import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import TagCarousel from './TagCarousel';
import WhoIAmLeft from './WhoIAmLeft';

const serializers = {
  types: {
    block: ({ children, node }) => {
      if (node.style === 'h4') {
        return <h3 className="split-lines">{children}</h3>;
      }
      return <p className="para split-lines">{children}</p>;
    },
  },
};

export default function WhoIAm({ data }) {
  return (
    <section id="who-i-am">
      <div className="wrapper">
        <WhoIAmLeft />

        <div className="right-content">
          <TagCarousel title="Who I am" />
          <div className="t3-text-block intro">
            <PortableText blocks={data._rawBlock1} serializers={serializers} />
          </div>
          <div className="t3-text-block dev">
            <PortableText blocks={data._rawBlock2} serializers={serializers} />

            <div className="all-links stagger-reveal">
              <p className="link open-tools">My tool stack</p>
              <p className="link open-favs">Favorites list</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
