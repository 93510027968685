import React from 'react';
import Line from '../../images/theme1/ico-line.svg';

export default function ToolCarousel({ data }) {
  return (
    <section className="tool-carousel">
      <div className="wrapper">
        <div className="tools-marquee">
          <div className="all-tools change-direction">
            {data.map((tool, i) => (
              <div className="tool" key={i}>
                <span>{tool.tool}</span>
                <img src={Line} alt="dash" className="dash" />
              </div>
            ))}
            {data.map((tool, i) => (
              <div className="tool" key={i}>
                <span>{tool.tool}</span>
                <img src={Line} alt="dash" className="dash" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
