import React from 'react';
import Heart from '../../images/theme3/ico-heart.svg';

export default function WhoIAmLeft() {
  return (
    <div className="left-content" id="fixed">
      <div
        className="icon-contain"
        data-scroll
        data-scroll-sticky
        data-scroll-target="#fixed"
      >
        <div className="all-icons-contain">
          <div className="all-icons">
            <div className="icons heart">
              <img src={Heart} alt="Heart" />
              <img src={Heart} alt="Heart" />
              <img src={Heart} alt="Heart" />
            </div>
            <div className="icons smile">
              <svg
                width="120"
                height="120"
                viewBox="0 0 120 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M120 120V0L0 0L0 120H120ZM34 51.4453V17H43V51.4453H34ZM77 51.4453V17H86V51.4453H77ZM35 103V94H26V85H17V76H26V85H35V94H85V85H94V76H103V85H94V94H85V103H35Z"
                  fill="#C6FA19"
                />
                <path
                  d="M43 17V26H34V17H43Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem second"
                />
                <path
                  d="M86 17V26H77V17H86Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem second"
                />
                <path
                  d="M43 26V35H34V26H43Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem first"
                />
                <path
                  d="M26 76V85H17V76H26Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem second"
                />
                <path
                  d="M35 85V94H26V85H35Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem first"
                />
                <path
                  d="M94 85V94H85V85H94Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem first"
                />
                <path
                  d="M103 76V85H94V76H103Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem second"
                />
                <path
                  d="M86 26V35H77V26H86Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem first"
                />
              </svg>
            </div>
            <div className="icons sparkle">
              <svg
                width="120"
                height="120"
                viewBox="0 0 120 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.2773 8.61328V0H25.6641V8.61328H34.2773ZM17.1094 34.3359V25.7227H8.55469V34.3359H17.1094ZM120 68.6133V60H111.387V68.6133H120ZM17.1094 120V111.445H8.55469V120H17.1094Z"
                  fill="#C6FA19"
                />
                <path
                  d="M107.109 119.997V107.165H120V98.6106H107.109V85.72H98.5547V98.6106H85.6641V107.165H98.5547V119.997H107.109Z"
                  fill="#C6FA19"
                  className="elem second"
                />
                <path
                  d="M42.832 111.109V85.3867H51.3867V76.832H77.1094V68.2773H51.3867V59.6641H42.832V34H34.2773V59.6641H25.6641V68.2773H0V76.832H25.6641V85.3867H34.2773V111.109H42.832Z"
                  fill="#C6FA19"
                  className="elem third"
                />
                <path
                  d="M94.2773 60V42.8906H102.832V34.3359H120V25.7227H102.832V17.168H94.2773V0H85.6641V17.168H77.1094V25.7227H60V34.3359H77.1094V42.8906H85.6641V60H94.2773Z"
                  fill="#C6FA19"
                  className="elem first"
                />
              </svg>
            </div>
            <div className="icons heart">
              <img src={Heart} alt="Heart" />
              <img src={Heart} alt="Heart" />
              <img src={Heart} alt="Heart" />
            </div>
            <div className="icons smile">
              <svg
                width="120"
                height="120"
                viewBox="0 0 120 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M120 120V0L0 0L0 120H120ZM34 51.4453V17H43V51.4453H34ZM77 51.4453V17H86V51.4453H77ZM35 103V94H26V85H17V76H26V85H35V94H85V85H94V76H103V85H94V94H85V103H35Z"
                  fill="#C6FA19"
                />
                <path
                  d="M43 17V26H34V17H43Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem second"
                />
                <path
                  d="M86 17V26H77V17H86Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem second"
                />
                <path
                  d="M43 26V35H34V26H43Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem first"
                />
                <path
                  d="M26 76V85H17V76H26Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem second"
                />
                <path
                  d="M35 85V94H26V85H35Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem first"
                />
                <path
                  d="M94 85V94H85V85H94Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem first"
                />
                <path
                  d="M103 76V85H94V76H103Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem second"
                />
                <path
                  d="M86 26V35H77V26H86Z"
                  fill="#C6FA19"
                  stroke="#C6FA19"
                  className="elem first"
                />
              </svg>
            </div>
            <div className="icons sparkle">
              <svg
                width="120"
                height="120"
                viewBox="0 0 120 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.2773 8.61328V0H25.6641V8.61328H34.2773ZM17.1094 34.3359V25.7227H8.55469V34.3359H17.1094ZM120 68.6133V60H111.387V68.6133H120ZM17.1094 120V111.445H8.55469V120H17.1094Z"
                  fill="#C6FA19"
                />
                <path
                  d="M107.109 119.997V107.165H120V98.6106H107.109V85.72H98.5547V98.6106H85.6641V107.165H98.5547V119.997H107.109Z"
                  fill="#C6FA19"
                  className="elem second"
                />
                <path
                  d="M42.832 111.109V85.3867H51.3867V76.832H77.1094V68.2773H51.3867V59.6641H42.832V34H34.2773V59.6641H25.6641V68.2773H0V76.832H25.6641V85.3867H34.2773V111.109H42.832Z"
                  fill="#C6FA19"
                  className="elem third"
                />
                <path
                  d="M94.2773 60V42.8906H102.832V34.3359H120V25.7227H102.832V17.168H94.2773V0H85.6641V17.168H77.1094V25.7227H60V34.3359H77.1094V42.8906H85.6641V60H94.2773Z"
                  fill="#C6FA19"
                  className="elem first"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
