import React from 'react';
import PortableText from '@sanity/block-content-to-react';

const serializers = {
  types: {
    block: ({ children, node }) => {
      if (node.style === 'h4') {
        return <h3 className="split-lines">{children}</h3>;
      }
      return <p className="para split-lines">{children}</p>;
    },
  },
};

export default function WhoIAm1({ data }) {
  return (
    <section className="who-i-am" id="who-i-am-1">
      <div className="wrapper col-grid">
        <div className="sec-title">
          <div className="title">
            <span>Who I am</span>
          </div>
        </div>

        <div className="who-content">
          <div className="who-texts">
            <PortableText blocks={data} serializers={serializers} />
          </div>
        </div>
      </div>
    </section>
  );
}
