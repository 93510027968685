import React from 'react';
import HeroAbout2 from './HeroAbout2';
import WhoIAm1 from './WhoIAm1';
import DesignQuote from './DesignQuote';
import WhoIAm2 from './WhoIAm2';
import AboutServices from './AboutServices';

export default function AboutContent2({ data }) {
  const { heroAbout, whoIAm, quote, toolsCarousel, myServices } =
    data.sanityAboutpage;
  return (
    <>
      <HeroAbout2 data={heroAbout} />
      <WhoIAm1 data={whoIAm._rawBlock1} />
      <DesignQuote data={quote} />
      <WhoIAm2 data={whoIAm._rawBlock2} />
      <AboutServices data={myServices} tools={toolsCarousel.allTools} />
    </>
  );
}
