import React from 'react';

export default function Squiggle3() {
  return (
    <svg
      width="368"
      height="152"
      viewBox="0 0 368 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="squiggle1"
    >
      <path
        d="M218.836 12.6244C154.191 2.42497 -4.34503 21.9736 2.19648 83.5947C8.73798 145.216 112.248 156.69 203.829 146.916C295.41 137.142 362.365 93.3695 365.828 55.9715C369.291 18.5734 320.037 2 265.781 2C211.525 2 156.115 8.37462 89.1602 28.7734"
        stroke="#F5C3B4"
        strokeWidth="4"
        strokeLinecap="round"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}
