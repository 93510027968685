import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import PageHead from '../components/PageHead';
import { modalAnimHandler } from '../uitilities/modalHandlers';

import HeroesModal from '../components/modals/HeroesModal';
import ToolsModal from '../components/modals/ToolsModal';
import FavsModal from '../components/modals/FavsModal';
import Layout from '../components/Layout';
import AboutContent1 from '../components/theme1/AboutContent1';
import AboutContent2 from '../components/theme2/AboutContent2';
import AboutContent3 from '../components/theme3/AboutContent3';

export function Head({ location, data }) {
  const { seo } = data.sanityAboutpage;

  return (
    <PageHead
      title={seo.title && seo.title}
      description={seo.description && seo.description}
      image={seo.ogImage && seo.ogImage.asset.url}
      location={location}
    />
  );
}

export default function AboutPage({ data, location }) {
  const [activeTheme, setActiveTheme] = useState(() => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem('themeRekh');
    }
    return null;
  });
  const [dummyState, setDummyState] = useState(false); // to trigger loco refresh when theme is changed

  useEffect(() => {
    if (document.querySelector('.open-heroes')) {
      const heroesLinks = document.querySelectorAll('.open-heroes');
      const heroesDiv = document.querySelector('.heroes-modal');
      modalAnimHandler(heroesLinks, heroesDiv);
    }
    if (document.querySelector('.open-tools')) {
      const toolsLinks = document.querySelectorAll('.open-tools');
      const toolsDiv = document.querySelector('.tools-modal');
      modalAnimHandler(toolsLinks, toolsDiv);
    }
    if (document.querySelector('.open-favs')) {
      const favsLinks = document.querySelectorAll('.open-favs');
      const favsDiv = document.querySelector('.favs-modal');
      modalAnimHandler(favsLinks, favsDiv);
    }
  });

  return (
    <>
      <HeroesModal theme={activeTheme} />
      <ToolsModal theme={activeTheme} />
      <FavsModal theme={activeTheme} />
      <Layout
        location={location}
        theme={activeTheme}
        setTheme={setActiveTheme}
        dummyState={dummyState}
        setDummyState={setDummyState}
        navScroll
      >
        {activeTheme === 'theme1' && <AboutContent1 data={data} />}
        {activeTheme === 'theme2' && <AboutContent2 data={data} />}
        {activeTheme === 'theme3' && <AboutContent3 data={data} />}
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityAboutpage {
      heroAbout {
        _rawTitle
        pic {
          asset {
            gatsbyImageData
          }
        }
        picMobile {
          asset {
            gatsbyImageData
          }
        }
      }
      whoIAm {
        _rawBlock1
        _rawBlock2
      }
      quote {
        title
        url
        name
        pic {
          asset {
            gatsbyImageData
          }
        }
      }
      toolsCarousel {
        allTools {
          tool
          logo {
            asset {
              gatsbyImageData
            }
          }
        }
      }
      myServices {
        title
        para
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
