import React from 'react';
import PortableText from '@sanity/block-content-to-react';

const serializers = {
  types: {
    block: ({ children, node }) => {
      if (node.style === 'h4') {
        return <h3 className="split-lines">{children}</h3>;
      }
      return <p className="para split-lines">{children}</p>;
    },
  },
};

export default function WhoIAm2({ data }) {
  return (
    <section className="who-i-am" id="who-i-am-2">
      <div className="wrapper col-grid">
        <div className="empty" />

        <div className="who-content">
          <div className="who-texts">
            <PortableText blocks={data} serializers={serializers} />
          </div>

          <div className="all-links stagger-reveal">
            <p className="link open-tools">My tool stack</p>
            <p className="link open-favs">Favorites list</p>
          </div>
        </div>
      </div>
    </section>
  );
}
