import React from 'react';
import HeroAbout3 from './HeroAbout3';
import WhoIAm from './WhoIAm';
import DesignQuote from './DesignQuote';
import AboutServices from './AboutServices';

export default function AboutContent3({ data }) {
  const { heroAbout, whoIAm, quote, toolsCarousel, myServices } =
    data.sanityAboutpage;

  return (
    <>
      <HeroAbout3 data={heroAbout} />
      <WhoIAm data={whoIAm} />
      <DesignQuote data={quote} />
      <AboutServices data={myServices} tools={toolsCarousel.allTools} />
    </>
  );
}
