import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LocomotiveScroll from 'locomotive-scroll';
/* eslint-disable */
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper';
/* eslint-enable */
import Close from '../../images/theme1/ico-close.svg';
import Close2 from '../../images/theme2/ico-close.svg';
import Close3 from '../../images/theme3/ico-close.svg';
import Swipe from '../../images/ico-swipe.svg';
import Scroll from '../../images/ico-mouse.svg';

export default function ToolsModal({ theme }) {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        toolStack {
          title
          designTools {
            name
            category
          }
          devTools {
            name
            category
          }
          soundTools {
            name
            category
          }
          projectTools {
            name
            category
          }
        }
      }
    }
  `);
  const toolsData = data.sanitySiteSettings.toolStack;

  useEffect(() => {
    if (theme) {
      const toolsModal = document.querySelector('.tools-modal');
      const modalContent = toolsModal.querySelector('.modal-content');
      const toolsScroll = new LocomotiveScroll({
        el: modalContent,
        smooth: true,
        lerp: 0.09,
        scrollbarContainer: toolsModal,
      });
      // Destroy when unmounts
      return () => {
        if (toolsScroll) toolsScroll.destroy();
      };
    }
  });

  return (
    <div className={`modal tools-modal ${theme}`}>
      {theme === 'theme1' && (
        <>
          <div className="modal-bg" />

          <div data-scroll-container className="modal-content">
            <div className="wrapper col-grid">
              <h2>{toolsData.title}</h2>
              <div className="main-content tools-content">
                <div className="tools-group">
                  <h3>Design</h3>
                  {toolsData.designTools.map((tool, i) => (
                    <div className="list-item tool-details" key={i}>
                      <div className="name">
                        <p className="para">{tool.name}</p>
                      </div>
                      <div className="category">
                        <p className="para small">{tool.category}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="tools-group">
                  <h3>Development</h3>
                  {toolsData.devTools.map((tool, i) => (
                    <div className="list-item tool-details" key={i}>
                      <div className="name">
                        <p className="para">{tool.name}</p>
                      </div>
                      <div className="category">
                        <p className="para small">{tool.category}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="tools-group">
                  <h3>Sound design</h3>
                  {toolsData.soundTools.map((tool, i) => (
                    <div className="list-item tool-details" key={i}>
                      <div className="name">
                        <p className="para">{tool.name}</p>
                      </div>
                      <div className="category">
                        <p className="para small">{tool.category}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="tools-group">
                  <h3>Project management</h3>
                  {toolsData.projectTools.map((tool, i) => (
                    <div className="list-item tool-details" key={i}>
                      <div className="name">
                        <p className="para">{tool.name}</p>
                      </div>
                      <div className="category">
                        <p className="para small">{tool.category}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="close-btn">
            <p>
              <img src={Close} alt="close" /> Close
            </p>
          </div>
        </>
      )}

      {theme === 'theme2' && (
        <>
          <div className="modal-bg" />

          <div data-scroll-container className="modal-content">
            <div className="heading">
              <h2>{toolsData.title}</h2>

              <p className="swipe-scroll subtitle">
                <span className="scroll subtitle">
                  Scroll down
                  <img src={Scroll} alt="scroll" /> or swipe
                  <img src={Swipe} alt="swipe" />
                </span>
                <span className="swipe subtitle">
                  Swipe
                  <img src={Swipe} alt="swipe" />
                </span>
              </p>
            </div>

            <div className="main-content tools-content">
              <Swiper
                className="tools-swiper"
                slidesPerView="auto"
                slideToClickedSlide
                modules={[FreeMode, Mousewheel]}
                freeMode={{
                  sticky: false,
                  momentumBounce: false,
                }}
                mousewheel={{ sensitivity: 4.8 }}
              >
                <SwiperSlide className="list-item tools-group">
                  <h4>Design</h4>
                  {toolsData.designTools.map((tool, i) => (
                    <div className="tool-details" key={i}>
                      <div className="name">
                        <p className="para">{tool.name}</p>
                      </div>
                      <div className="category">
                        <p className="para small">{tool.category}</p>
                      </div>
                    </div>
                  ))}
                </SwiperSlide>
                <SwiperSlide className="list-item tools-group dev">
                  <h4>Development</h4>
                  {toolsData.devTools.map((tool, i) => (
                    <div className="tool-details" key={i}>
                      <div className="name">
                        <p className="para">{tool.name}</p>
                      </div>
                      <div className="category">
                        <p className="para small">{tool.category}</p>
                      </div>
                    </div>
                  ))}
                </SwiperSlide>
                <SwiperSlide className="list-item tools-group">
                  <h4>Sound design</h4>
                  {toolsData.soundTools.map((tool, i) => (
                    <div className="tool-details" key={i}>
                      <div className="name">
                        <p className="para">{tool.name}</p>
                      </div>
                      <div className="category">
                        <p className="para small">{tool.category}</p>
                      </div>
                    </div>
                  ))}
                </SwiperSlide>
                <SwiperSlide className="list-item tools-group">
                  <h4>Project management</h4>
                  {toolsData.projectTools.map((tool, i) => (
                    <div className="tool-details" key={i}>
                      <div className="name">
                        <p className="para">{tool.name}</p>
                      </div>
                      <div className="category">
                        <p className="para small">{tool.category}</p>
                      </div>
                    </div>
                  ))}
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          <div className="close-btn">
            <img src={Close2} alt="close" />
          </div>
        </>
      )}

      {theme === 'theme3' && (
        <>
          <div className="modal-bg type-1" />
          <div className="modal-bg type-2" />

          <div data-scroll-container className="modal-content">
            <div className="main-content tools-content">
              <h2>{toolsData.title}</h2>
              <div className="tools-group">
                <h3>Design</h3>
                {toolsData.designTools.map((tool, i) => (
                  <div className="list-item tool-details" key={i}>
                    <div className="name">
                      <p className="para">{tool.name}</p>
                    </div>
                    <div className="category">
                      <p className="para small">{tool.category}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="tools-group">
                <h3>Development</h3>
                {toolsData.devTools.map((tool, i) => (
                  <div className="list-item tool-details" key={i}>
                    <div className="name">
                      <p className="para">{tool.name}</p>
                    </div>
                    <div className="category">
                      <p className="para small">{tool.category}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="tools-group">
                <h3>Sound design</h3>
                {toolsData.soundTools.map((tool, i) => (
                  <div className="list-item tool-details" key={i}>
                    <div className="name">
                      <p className="para">{tool.name}</p>
                    </div>
                    <div className="category">
                      <p className="para small">{tool.category}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="tools-group">
                <h3>Project management</h3>
                {toolsData.projectTools.map((tool, i) => (
                  <div className="list-item tool-details" key={i}>
                    <div className="name">
                      <p className="para">{tool.name}</p>
                    </div>
                    <div className="category">
                      <p className="para small">{tool.category}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="close-btn">
            <img src={Close3} alt="close" />
          </div>
        </>
      )}
    </div>
  );
}
