import React from 'react';
import HeroAbout1 from './HeroAbout1';
import WhoIAm1 from './WhoIAm1';
import ToolCarousel from './ToolCarousel';
import WhoIAm2 from './WhoIAm2';
import DesignQuote from './DesignQuote';
// import AboutServices from './AboutServices';

export default function AboutContent1({ data }) {
  const { heroAbout, whoIAm, quote, toolsCarousel, myServices } =
    data.sanityAboutpage;

  return (
    <>
      <HeroAbout1 data={heroAbout} />
      <WhoIAm1 data={whoIAm._rawBlock1} />
      <ToolCarousel data={toolsCarousel.allTools} />
      <DesignQuote data={quote} />
      <WhoIAm2 data={whoIAm._rawBlock2} />
      {/* <AboutServices data={myServices} tools={toolsCarousel.allTools} /> */}
    </>
  );
}
