import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import QuoteIcon from '../../images/theme3/ico-quote.svg';

export default function DesignQuote({ data }) {
  return (
    <section className="design-quote">
      <div className="wrapper">
        <div className="quote-contain">
          <div className="heading">
            <div className="designer-info">
              <div className="pic">
                <GatsbyImage
                  image={data.pic.asset.gatsbyImageData}
                  alt={data.name}
                />
              </div>
              <p>{data.name}</p>
            </div>
            <div className="quote-icon">
              <img src={QuoteIcon} alt="Quote icon" />
            </div>
          </div>

          <div className="quote-text">
            <h3 className="split-lines">
              {data.title}{' '}
              {data.url && (
                <a
                  href={data.url}
                  className="link element-reveal"
                  target="_blank"
                  rel="noreferrer"
                >
                  Video link
                </a>
              )}
            </h3>
          </div>

          <div className="all-links">
            <p className="link open-heroes element-reveal">My heroes & gurus</p>
          </div>
        </div>
      </div>
    </section>
  );
}
