import React from 'react';
import { Link } from 'gatsby';

export default function AboutServices({ data, tools }) {
  return (
    <section id="about-services">
      <div className="wrapper">
        <div className="heading">
          <h2 className="element-reveal">{data.title}</h2>
          <Link to="/contact" className="button element-reveal">
            Let's work together
          </Link>
        </div>
      </div>

      <div className="tools-marquee">
        <div className="all-tools">
          {tools.map((tool, i) => (
            <p className="tool-pill" key={i}>
              {tool.tool}
            </p>
          ))}
          {tools.map((tool, i) => (
            <p className="tool-pill" key={i}>
              {tool.tool}
            </p>
          ))}
        </div>
      </div>

      <div className="tools-marquee reverse">
        <div className="all-tools">
          {tools.map((tool, i) => (
            <p className="tool-pill" key={i}>
              {tool.tool}
            </p>
          ))}
          {tools.map((tool, i) => (
            <p className="tool-pill" key={i}>
              {tool.tool}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
}
