import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import Arrow from '../../images/theme2/ico-arrow-2.svg';
import BadgeTools from '../../images/theme2/badge-tools.svg';
import BadgeFavs from '../../images/theme2/badge-fav.svg';

const serializers = {
  types: {
    block: ({ children, node }) => {
      if (node.style === 'h4') {
        return <h3 className="big split-lines">{children}</h3>;
      }
      return <p className="para split-lines">{children}</p>;
    },
  },
};

export default function WhoIAm2({ data }) {
  return (
    <section className="who-i-am type2">
      <div className="wrapper">
        <div className="who-content">
          <div className="who-body">
            <div className="floating-tag green ball-reveal">
              <div className="tag-content">
                <p>Cutting edge</p>
              </div>
            </div>
            <div className="who-texts">
              <PortableText blocks={data} serializers={serializers} />
            </div>
          </div>

          <div className="all-balls ball-reveal-stagger">
            <div className="modal-btn open-tools">
              <div className="arrow">
                <img src={Arrow} alt="Arrow" />
              </div>
              <img src={BadgeTools} alt="Badge" className="badge" />
            </div>
            <div className="ball sky" />
            <div className="ball green" />
            <div className="ball off-white" />
            <div className="ball pink" />
            <div className="modal-btn open-favs">
              <div className="arrow">
                <img src={Arrow} alt="Arrow" />
              </div>
              <img src={BadgeFavs} alt="Badge" className="badge" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
